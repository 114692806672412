import FzAlert from "@Components/Layout/FzAlert";
import NProgress from "@Components/Loaders/NProgress";
import SEO from "@Components/SEO";
import React from "react";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
  useRouteError
} from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import styles from "./styles.module.scss";

//////////////////////////////////////////////////////////////////////////////////////
// Redirect Component
//////////////////////////////////////////////////////////////////////////////////////
const GoTo = ({ to: url }) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = React.useState(5);

  //////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////

  // Timer para redirecionar pra home do painel, não manter nessa página
  React.useEffect(() => {
    if (!timeLeft) {
      return navigate("/", { replace: true });
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Abre a url para redirect
  React.useEffect(() => {
    window.open(url);
  }, []);

  //////////////////////////////////////////////////////////////////////
  // Render()
  //////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="d-flex vw-100 vh-100 align-items-center justify-content-center">
        <div className="d-block text-center">
          <b className="text-purple-3">Redirecionando para:</b>{" "}
          <a
            className="text-purple-1"
            target="_blank"
            href="{url}"
          >
            {url}
          </a>
          <p>
            Caso a nova página não abrir, verifique se o nosso painel está
            bloqueado em seu navegador ou plugin anti-popup.
          </p>
          <p>Essa página fechará em {timeLeft} segundos...</p>
        </div>
      </div>
    </>
  );
};

const Redirect = ({ url }) => {
  let isUrl = url.indexOf("http://") == 0 || url.indexOf("https://") == 0;
  if (isUrl) {
    return <GoTo to={url} />;
  } else {
    return <Navigate to={url} />;
  }
};

//////////////////////////////////////////////////////////////////////////////////////
// Error Component
//////////////////////////////////////////////////////////////////////////////////////
const ErrorBoundary = () => {
  const { showNewMessage } = useIntercom();

  let error = useRouteError();
  const navigate = useNavigate();
  const location = useLocation();

  const handleHome = () => {
    navigate("/meu/", { replace: true });
  };
  const handleRefresh = () => {
    navigate(location.path, { replace: true });
  };

  return (
    <div className={styles.routerError}>
      <FzAlert
        className="bg-yellow-10 text-center w-100"
        fixed
      >
        <p className="fw-600 mb-0 fs-18">Ooops! Ocorreu um problema aqui! 😔</p>
        <pre className="mb-0">Tente novamente ou contate o suporte!</pre>
        {/* {isSuperAdmin && <> */}
        <div className="px-5 mt-1">
          <pre className={styles.error}>
            {error.stack.split("\n").map((error, index) => (
              <p
                className={`mb-0 ${index > 0 ? "text-yellow-1" : "text-black"}`}
                key={index}
              >
                {error}
              </p>
            ))}
          </pre>
        </div>
        {/* </>} */}
        <div className="d-block text-center mt-2">
          <button
            className="btn btn-bg-purple mx-1"
            onClick={handleRefresh}
          >
            Tentar novamente
          </button>
          <button
            className="btn btn-bg-purple mx-1"
            onClick={handleHome}
          >
            Ir para Página Inicial
          </button>
          <button
            className="btn btn-bg-red mx-1"
            onClick={() =>
              showNewMessage(
                "Olá, estou com problemas no painel!\n\nErro:\n" +
                  error.stack.split("\n")?.[0] +
                  "\n\n Endereço:\n https://meu.fidelizi.com.br" +
                  location.pathname +
                  "\n\nDescrição do meu problema: \n"
              )
            }
          >
            Chamar o Suporte
          </button>
        </div>
      </FzAlert>
    </div>
  );
};

/**
 *
 * @param {object} routes
 * @param {string} parentComponent
 * @returns {null}
 */
const BakeRoutes = ({
  routes,
  componentsFolder,
  parentComponent,
  parentPrefix
}) => {
  // console.log({ routes, componentsFolder, parentComponent });

  return routes.map(
    (
      {
        path,
        index,
        caseSensitive,
        absolute,
        component,
        dashboard,
        children,
        redirect,
        abstract,
        title = undefined,
        prefix = undefined,
        meta = <></>,
        description = undefined,
        action,
        loader,
        shouldRevalidate
      },
      i
    ) => {
      if (parentPrefix && !prefix) {
        prefix = parentPrefix;
      }
      // Construção do caminho relativo dos componentes, se houver a chave absolute, é pra pegar o caminho literal (!use com cautela!)
      if (component && !absolute) {
        let parent = parentComponent;
        if (parent && component.includes("../")) {
          parent = parent.split("/").slice(0, -1).join("/");
        }
        component = component.replace("../", "/");
        component = (parent || "") + component;
      }

      let Element = (props) => {
        // Se for uma rota abstrata, ela deve conter apenas um Outlet
        if (abstract) {
          return (
            <>
              <SEO
                title={title || path}
                prefix={prefix}
                description={description}
              >
                {meta}
              </SEO>
              <Outlet />
            </>
          );
        }
        // Se for redirect, carrega o componente custom de redirect
        if (redirect) {
          return <Redirect url={redirect} />;
        }
        // Se for dashboard, carrega o componente da pasta dashboard
        if (dashboard) {
          let Component = React.lazy(() => import(`@Dashboards/${dashboard}`));
          return (
            <React.Suspense fallback={<NProgress />}>
              <SEO
                title={title || path}
                prefix={prefix}
                description={description || ""}
              >
                {meta}
              </SEO>
              <Component />
            </React.Suspense>
          );
        }
        // Se for component, carrega o componente da pasta Pages/<componentsFolder>/...
        if (component) {
          let Component = React.lazy(() =>
            import(
              `@Pages/${
                absolute ? component : `${componentsFolder}${component}`
              }`
            )
          );
          return (
            <>
              <React.Suspense fallback={<NProgress />}>
                <SEO
                  title={title || path}
                  prefix={prefix}
                  description={description || ""}
                >
                  {meta}
                </SEO>
                <span className="animate fadeInDown">
                  <Component />
                </span>
              </React.Suspense>
            </>
          );
        }
        // Se for nenhum, cria um redirect para notfound como callback a fim de evitar erros
        // return <Navigate to="/notfound"/>;
      };

      return (
        <Route
          errorElement={<ErrorBoundary />}
          path={path}
          caseSensitive={caseSensitive}
          index={index}
          key={i}
          element={<Element />}
          action={action}
          loader={loader}
          shouldRevalidate={shouldRevalidate}
        >
          {children &&
            children.length > 0 &&
            BakeRoutes({
              routes: children,
              componentsFolder,
              parentComponent: component,
              parentPrefix: prefix
            })}
        </Route>
      );
    }
  );
};

export default BakeRoutes;
